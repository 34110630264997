
// -----------------------------------
// ||           Carousels           ||
// -----------------------------------


// -------------- Options --------------
$carousel_id: 'carousel_exemple';
$carousel_height: 40vw;
$carousel_width: 100%;
$carousel_control_width: 10%;
$carousel_control_size: 2rem;
$carousel_control_align: center; // top, bottom, center
$carousel_control_distance: 80px;
$carousel_indicators_bottom_distance: 30px;
$carousel_indicators_height: 12px;
$carousel_indicators_width: 12px;
$carousel_indicators_spacing: 6px;
$carousel_indicators_color: rgba($color_white, 0);
$carousel_indicators_border: 1px solid $color_white;
$carousel_indicators_borderradius: 1000px;
$carousel_indicators_active_color: rgba($color_white, 0);
$carousel_indicators_active_border: keep; // keep = garder valeur non active
$carousel_indicators_center_size: 0px;
$carousel_indicators_center_color: rgba($color_white, 0);
$carousel_indicators_active_center_size: 6px;
$carousel_indicators_active_center_color: rgba($color_white, 1);
$carousel_indicators_transition: 0.5s;
// -------------------------------------
##{$carousel_id} {
	position: relative;
	height: $carousel_height;
	width: $carousel_width;
	.content_over_carousel {
		position: absolute;
		z-index: 10;
	}
	.carousel-inner {
		position: relative; height: 100%; width: 100%;
		.carousel-item {
			height: 100%; width: 100%;
			img {
				position: absolute;
				z-index: -100;
				top: 0; left: 0;
				width: 100%; height: 100%;
				object-fit: cover;
			}
		}
	}
	.carousel-control {
		width: $carousel_control_width;
		@if $carousel_control_align == top { 
			align-items: start;
			padding-top: $carousel_control_distance;
		}
		@if $carousel_control_align == bottom {
			align-items: end;
			padding-bottom: $carousel_control_distance;
		}
	}
	.carousel-control-prev { font-size: $carousel_control_size; }
	.carousel-control-next { font-size: $carousel_control_size; }
	.carousel-indicators {
		@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
		cursor: pointer;
		margin: 0;
		bottom: $carousel_indicators_bottom_distance;
		li {
			@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
			height: $carousel_indicators_height;
			width: $carousel_indicators_width;
			margin: 0 $carousel_indicators_spacing / 2;
			background-color: $carousel_indicators_color;
			border: $carousel_indicators_border;
			border-radius: $carousel_indicators_borderradius;
			.carousel-indicator-inside {
				@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
				@extend %absolutecenter_xy;
				border-radius: $carousel_indicators_borderradius;
				height: $carousel_indicators_center_size;
				width: $carousel_indicators_center_size;
				background-color: $carousel_indicators_center_color;
			}
		}
		li.active {
			background-color: $carousel_indicators_active_color;
			@if $carousel_indicators_active_border == keep {
				border: $carousel_indicators_border;
			} else {
				border: $carousel_indicators_active_border;
			}
			.carousel-indicator-inside {
				@extend %absolutecenter_xy;
				height: $carousel_indicators_active_center_size;
				width: $carousel_indicators_active_center_size;
				background-color: $carousel_indicators_active_center_color;
			}
		}
	}
}