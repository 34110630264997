
// ---------------------------------
// ||            Forms            ||
// ---------------------------------

form {
	label {
		font-size: $form_label_fontsize;
		font-weight: $form_label_fontweight;
		font-style: $form_label_fontstyle;
		margin-bottom: $form_label_marginbottom;
		padding-left: $form_label_paddingleft;
	}

	.form-check-label {
		font-size: $form_radiochecklabel_fontsize;
		font-weight: $form_radiochecklabel_fontweight;
		font-style: $form_radiochecklabel_fontstyle;
		margin-bottom: $form_radiochecklabel_marginbottom;
		padding-left: $form_radiochecklabel_paddingleft;
	}

	.form-control {
		padding: $form_input_padding;
		border: $form_input_border;
		border-radius: $form_input_borderradius;
		color: $form_input_fontcolor;
		background-color: $form_input_bgcolor;
		font-size: $form_input_fontsize;
		font-weight: $form_input_fontweight;
		font-style: $form_input_fontstyle;
		margin-bottom: $form_input_marginbottom;
	}
	.form-control.green { border: $form_input_border_green; }
	.form-control.red { border: $form_input_border_red; }

	.form-control::placeholder { color: $form_input_placeholder_color; }
	.form-control:-ms-input-placeholder { color: $form_input_placeholder_color; }
	.form-control::-ms-input-placeholder { color: $form_input_placeholder_color; }

	.loader {
		pointer-events: none;
		position: absolute;
		z-index: 1000;
		width: $form_loader_size + $form_loader_padding;
		height: $form_loader_size + $form_loader_padding;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: $form_loader_bgcolor;
		border-radius: 1000px;
	}
	.loader:after {
		content: " ";
		display: block;
		width: $form_loader_size;
		height: $form_loader_size;
		margin: $form_loader_padding / 2;
		border-radius: 50%;
		border: $form_loader_thickness solid $form_loader_color;
		border-color: $form_loader_color transparent $form_loader_color transparent;
		animation: loader $form_loader_speed linear infinite;
	}
	@keyframes loader {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
}


// Modale confirmation contact
.modal-backdrop {
	display: none;
}
.form_error_box {
	color: $color_black;
    .modal-title {
        font-weight: 700;
    }

    .form_modal_ok {
        color: $color_success;
    }

    .form_modal_fail {
        color: $color_error;
    }

    i {
        float: left;
        margin-right: 16px;
        font-size: 3rem;
        margin-bottom: 16px;
    }

    h6 {
        margin-top: 26px;
        font-weight: 700;
    }

    ul {
        padding: 0;
        list-style: none;

        li {
            border-top: 1px solid #e0e0e0;
            font-size: 0.9rem;
            padding-top: 8px;
            margin-bottom: 10px;
        }
    }
}