
%absolutecenter_x {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

%absolutecenter_y {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

%absolutecenter_xy {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

%imgcover {
	height: 100%;
	width: 100%;
	object-fit: cover;
}