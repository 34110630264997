
// ----------------------------------
// ||            Global            ||
// ----------------------------------

html { 
	overflow-x: hidden;
	overflow-y: scroll;
	margin: 0; padding: 0;
	width: 100%;
}

body {
	background-color: $main_bg_color;
	margin: 0; padding: 0;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
	width: 100%;
	padding-right: 0 !important; // Pour contrer les 17px des modales boostrap
}

section {
	position: relative;
	width: 100%;
}

section a {
	color: $links_color;
	font-weight: $links_weight;
	text-decoration: $links_decoration;
}

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
div[type="submit"],
*[type="submit"],
button {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
}

/*.full_site {
	position: relative;
	min-height: 100vh;
}*/

*, *:focus, *:active {
	outline: none !important;
}

.hidden {
	display: none;
}

.admin_link {
	position: absolute;
	z-index: 10000;
	background-color: transparent;
	border-top: 26px solid #343a40;
	border-left: 26px solid #343a40;
	border-right: 26px solid transparent;
	border-bottom: 26px solid transparent;
	top: -36px;
	left: -36px;
	animation-name: admin_link_start;
    animation-duration: 0.8s;
	transition: 0.3s;

	i {
		color: white;
		position: absolute;
		top: -20px;
		left: -20px;
	}
}
.admin_link:hover {
	top: 0px;
	left: 0px;
}
@keyframes admin_link_start {
    from {top: 0px;left: 0px;}
    to {top: -36px;left: -36px;}
}

.grecaptcha-badge { display: none; }

.bg_transp { background-color: transparent !important; }


.scrollnav {
	position: fixed;
	z-index: 100;
	right: 0;
	bottom: 10%;
	/* transform: translateY(-50%); */
	width: 50px;
	/* background-color: rgba($color_white, 0.2); */

	.scrollnav_link {
		height: 10px;
		width: 10px;
		background-color: rgba($color_black, 0.3);
		margin: 20px 50%;
		border-radius: 1000px;
		margin-left: 50%;
		transform: translateX(-50%);
	}
	.scrollnav_link:hover {
		background-color: rgba($color_black, 0.5);
	}
	.scrollnav_link.active {
		background-color: rgba($color_black, 1);
	}
}

* {
	font-family: "interface", sans-serif;
}

.altfont {
	font-family: "program", sans-serif;
	font-weight: 900;
	font-style: italic;
	* {
		font-family: "program", sans-serif;
		font-weight: 900;
		font-style: italic;
	}
}

.bg_white {
	background-color: $color_white;
}

.txt_vert { color: $color_vert; }
.txt_black { color: $color_black; }
.txt_marron { color: $color_marron; }
.txt_gris { color: $color_gris; }

.htmltable { display:table; }
.table-row { display:table-row; }
.table-cell { display:table-cell; }

.popover {
	max-width: 350px;

	.popover-header {
		white-space: nowrap;
		color: $color_gris;

		.title {
			display: inline-block;
			font-weight: 900;
			font-style: italic;
		}
	}

	.close_pop {
		cursor: pointer;
		display: inline-block;
		margin-left: 10px;
	}
}

.search_container {
	padding: 0 !important;
	margin-left: 14px;
	text-align: center;
}

.search_button_container {
	cursor: pointer;
	background-color: transparent;
	padding: 0 !important;

	.search_button {
		cursor: pointer;
		margin-right: 6px;
	}

	i {
		color: $color_gris;
		font-size: 1.8rem;
		transform: translateY(5px);
	}
}

.search_info {
	color: $color_gris;
}

.search_form_container {
	display: flex;

	.search_form {
		display: flex;
		width: 100%;
	}

	input {
		flex: 1;
		background-color: rgba($color_gris, 0.3);
		border: none;
		padding: 5px 8px;
		border-radius: 10px;
	}

	.search_submit {
		cursor: pointer;
		color: $color_white;
		background-color: $color_gris;
		font-size: 1rem;
		padding: 3px 8px;
		margin-left: 7px;
	}
}

.popover_content_container { display: none; }

// ----------------- CSS SPECIFIQUE AU SITE -----------------

.index .encart_hautgauche {
	width: 70vw;
	.triangle_coin {
		border-left: 35vw solid rgba($color_vert, 0.8);
		border-top: 35vw solid rgba($color_vert, 0.8);
		border-bottom: 35vw solid transparent;
		border-right: 35vw solid transparent;
	}
}
.encart_hautgauche {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: $encart_width;

	.logo {
		position: absolute;
		z-index: 2;
		top: 40px;
		left: 40px;
		width: 30vw;
		max-width: 400px;
		min-width: 230px;
	}

	.triangle_coin {
		border-left: $encart_width/2 solid rgba($color_vert, 0.8);
		border-top: $encart_width/2 solid rgba($color_vert, 0.8);
		border-bottom: $encart_width/2 solid transparent;
		border-right: $encart_width/2 solid transparent;
	}
}

.encart_basdroite {
	position: absolute;
	z-index: 0;
	bottom: 0;
	right: 0;
	width: 60vw;

	.triangle_coin {
		border-bottom: 30vw solid rgba($color_gris, 0.8);
		border-right: 30vw solid rgba($color_gris, 0.8);
		border-left: 30vw solid transparent;
		border-top: 30vw solid transparent;
	}
}

@include media-breakpoint-down(sm) {
	.encart_hautgauche, .index .encart_hautgauche {
		width: 100vw;

		.triangle_coin {
			border-left: 50vw solid rgba($color_vert, 0.8);
			border-top: 50vw solid rgba($color_vert, 0.8);
			border-bottom: 50vw solid transparent;
			border-right: 50vw solid transparent;
		}
	}

	.encart_basdroite {
		width: 100vw;

		.logo {
			
		}

		.triangle_coin {
			border-bottom: 50vw solid rgba($color_gris, 0.8);
			border-right: 50vw solid rgba($color_gris, 0.8);
			border-left: 50vw solid transparent;
			border-top: 50vw solid transparent;
		}
	}
}


.overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 3;
	color: $color_white;

	* {
		margin: 0;
	}

	.phrase_haut {
		text-align: center;
		font-size: 1.3rem;
		font-weight: 300;
		letter-spacing: 0.25rem;
	}

	h3.phrase_haut {
		font-size: 1.8rem;
	}

	.compteur {
		font-family: "program", sans-serif;
		font-size: calc(5rem + 3.5vw);
		line-height: calc(5rem + 3.5vw);
		opacity: 0.9;
		transform: translateX(-20px);
		font-weight: 900;
		font-style: italic;
	}

	.phrase_bottom {
		font-family: "program", sans-serif;
		font-size: 2.4rem;
		transform: translateX(-13vw);
		font-weight: 900;
		font-style: italic;
	}

	.trait_oblique {
		position: absolute;
		width: 200px;
		right: 0;
		top: 0;
		transform: translateX(40%);
	}

	.filet {
		margin-top: 30px;
	}
}

.filet {
	height: 2px;
	width: 150px;
	background-color: $color_white;
	margin: auto;
}

.fleche {
	position: absolute;
	z-index: 2;
	left: 50%;
	top: 98vh;
	transform: translate(-50%, -100%);
	width: 35px;
}

.btn {
	border-radius: 10px;
	padding: 2px 20px;
	font-size: 1.3rem;
	font-weight: 900;
	font-style: italic;
	letter-spacing: 0.08rem;
	color: $color_white;
	text-decoration: none;
}

.bouton_type_1 {
	background-color: $color_vert;
}
.bouton_type_1:hover {
	color: $color_white;
	background-color: lighten($color_vert, 4%);
}

.bouton_type_2 {
	min-width: 200px;
	background-color: $color_gris;
}

.mobile_btn {
	//display: block !important;
	font-size: 1.3rem !important;
	font-weight: 900 !important;
	font-style: italic !important;
	letter-spacing: 0.075rem !important;
	width: 100%;
	padding: 10px !important;
}

.triangle_container {
	position: relative;
}
.triangle {
	pointer-events: none;
	position: absolute;
	z-index: 1;
	width: 100%;
	border-left: 50vw solid transparent;
	border-right: 50vw solid transparent;
	border-top: $hauteur_triangles/2 solid transparent;
	border-bottom: $hauteur_triangles/2 solid transparent;
}
.triangle.basgauche { bottom: 0; left: 0; }
.triangle.basdroite { bottom: 0; right: 0; }
.triangle.basdroite.gris {
	border-right: 50vw solid $color_gris;
	border-bottom: $hauteur_triangles/2 solid $color_gris;
}
.triangle.hautgauche.vert {
	border-left: 50vw solid $color_vert;
	border-top: $hauteur_triangles/2 solid $color_vert;
}



.corps {
	padding: 20px 0;
	z-index: 5;
	min-height: 400px;

	p {
		font-size: 1.2rem;
		line-height: 1.5rem;
		font-weight: 300;
		margin-bottom: 24px;
	}
}

.corps.gradiant {
	color: $color_white;
	background: linear-gradient($color_gris, $color_vert);
}

.gros_paragraphe, .gros_paragraphe p {
	font-size: 1.75rem;
	line-height: 1.2;
	font-weight: 900;
	font-style: italic;
	letter-spacing: 0.08rem;
	margin-bottom: 30px;
}
.gros_paragraphe:last-child {
	margin-bottom: 0;
}

.titrage_bouton {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;

	h2 {
		font-size: 2.1rem;
		margin: 0;
	}
}

.event_list {
	margin-bottom: 30px;
	width: 100%;

	> a {
		//display: block;
		position: relative;
		color: $color_white;
		text-decoration: none;
	}
	> a:hover {
		background: linear-gradient(to right, rgba($color_vert, 1), rgba($color_vert, 0));
	}

	td, .table-cell {
		padding: 4px;
		font-size: 1.1rem;
		font-weight: 300;
	}

	td.live, .table-cell.live {
		width: 70px;
	}

	td.name, td.event_name, .table-cell.name, .table-cell.event_name {
		text-transform: uppercase;

		p {
			margin: 0;
			line-height: 1.1rem;
		}
	}

	td.pdf, .table-cell.pdf {
		width: 55px;
	}

	.btn {
		font-weight: 700;
		font-style: normal;
		font-size: 1rem;
		line-height: 1rem;
		letter-spacing: 0;
		padding: 3px 8px;
	}

	.btn.disabled {
		pointer-events: none;
		background-color: #b0b0b0;
		opacity: 0.7;
	}
}

.results_list {
	width: 100%;

	td, .table-cell {
		text-align: center;
	}

	thead, .head {
		color: $color_white;
		background-color: $color_gris;

		td, .table-cell {
			font-size: 1.1rem;
			//line-height: 1.2rem;
			font-weight: 700;
			font-style: italic;
			padding: 4px 12px;
		}
	}

	td, .table-cell {
		padding: 1px 12px;
		font-size: 1.05rem;
		//line-height: 1.1rem;
		font-weight: 300;
	}

	tr:nth-child(even), .table-row:nth-child(even) {
		background-color: rgba($color_gris, 0.15);
	}

	a.table-row {
		text-decoration: none;
		color: $color_gris;
	}
	a.table-row:hover {
		color: $color_white;
		background-color: $color_gris;
	}


	.table-row.last * {
		font-weight: 700;
		color: $color_white;
		background-color: $color_gris;
	}
	
}

@include media-breakpoint-down($responsive_breakpoint) {
	.event_list {
		margin-bottom: 60px;

		.table-row {
			display: flex;
			flex-wrap: wrap;
			padding: 10px 0;
			border-bottom: 1px solid $color_white;

			.table-cell {
				padding: 0;
			}

			.event_date, .date {
				font-weight: 700;
				margin-right: 10px;
			}

			.event_location, .location {
				font-weight: 700;
			}

			.event_name, .name {
				width: 100%;
			}

			.name {
				margin-bottom: 6px;
			}

			.live, .pdf, .xls, .infos, .inscription {
				order: 10;
				width: auto;
				margin-right: 10px;
				margin-bottom: 10px;

				a {
					margin: 0;
				}
			}

			.live {
				margin-left: auto;
			}

			.xls {
				margin-right: auto;
			}
		}

		.btn {
			margin-top: 20px;
		}
	}
}

/*@include media-breakpoint-down(sm) {
	.results_list {
		.head {
			.table-cell {
				font-size: 0.8rem;
			}
		}
	}
}*/

.select_year {
	cursor: pointer;
	position: absolute;
	top: -30px;
	right: 15px;
	transform: translateY(-100%);
	padding: 4px;
	width: 300px;
	border: 1px solid $color_white;
	border-radius: 10px;
	color: $color_white;
	background-color: transparent;
	font-size: 1.1rem;
	letter-spacing: 0.06rem;
	font-weight: 900;
	font-style: italic;
	text-align: center;
	background-image: url('/images/select_arrow.png');
    background-position : right center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    text-align-last: center;

    option {
    	background-color: $color_gris;
    	border: 1px solid $color_white;
    }
}

.year_container.active {
	display: block;
}
.year_container {
	display: none;

	iframe {
		border: none;
		padding: 0;
		width: 100%;
		min-height: 800px;
		background-color: transparent;
	}
}

.old_results_body {
	font, td p {
		display: flex;

		.btn {
			font-size: 1rem;
			line-height: 1rem;
			font-weight: 700;
			letter-spacing: 0.1;
			padding: 3px 8px;
			font-style: initial;
			margin-right: 6px !important;
		}
	}

	table {
		width: 100%;

		td {
			padding: 5px 0;
		}

		tr, td {
			background-color: transparent;
		}

		p, a, font {
			color: $color_white;
			font-size: 1.1rem;
			font-weight: 300;
			margin: 0 !important;
		}
	}
}

.epreuve_select_table {
	width: auto !important;

	p {
		margin: 6px;
		margin-left: 0;
		padding: 5px;
		background-color: $color_white;
	}

	td {
		margin-right: 20px;
		a { color: $color_black !important; }
		a:hover {
			color: $color_vert !important;
		}
	}
	
}

.old_results {

	blockquote {
		table {
			width: 100% !important;

			tr:nth-child(even) {
				background-color: lighten($color_gris, 30%);
			}
			tr:nth-child(odd) {
				background-color: $color_white;
			}
		}
	}

	.xl25 {
		padding: 10px;
	}
}


@include media-breakpoint-down($responsive_breakpoint) {
	.select_year {
		right: 50%;
		transform: translate(50%, -100%);
		background-color: $color_gris;
	}
}


.module_timeinlive_container {
	position: relative;
	margin-top: 50px;
	min-height: 140px;

	.module_timeinlive {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
	}
}

.module_timeinlive {
	position: relative;
	background-color: $color_gris;
	border-radius: 20px;
	padding: 30px;
	text-align: center;

	* {
		font-weight: 900;
		font-style: italic;
	}

	.logo {
		width: 200px;
	}

	h1 {
		font-size: 3rem;
		margin: 0;
	}

	.illu_mobile {
		width: 230px;
	}

	.bouton_type_1 {
		position: absolute;
		bottom: -10px;
		left: 50%;
		transform: translateX(-50%);
	}
	.fleche_bas {
		position: absolute;
		bottom: -80px;
		left: 50%;
		transform: translateX(-50%);
	}
}

@include media-breakpoint-down($responsive_breakpoint) {
	.module_timeinlive_container {
		margin-bottom: 80px;
		.module_timeinlive {
			position: relative;
		}
	}
}

.container.relative {
	position: relative;
}

.container.small {
	max-width: 900px;
}


.container.results {
	padding: 35px;
	color: $color_gris;
}

.entete_results {
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
	text-align: right;

	.bouton_retour_container {
		text-align: left;
		margin-bottom: 10px;

		i {
			font-size: 1rem;
		}
	}

	.titre {
		//flex: 1;
		padding-right: 60px;

		h2 {
			font-size: 3.3rem;
			line-height: 3.3rem;
			margin: 0;
		}

		.date {
			color: $color_vert;
		}
	}

	.race h2 {
		font-weight: 700;
	}

	.choix_epreuve {
		padding: 3.8rem;
		padding-bottom: 0;
	}
}

@include media-breakpoint-down(sm) {
	.entete_results {
		.titre {
			h2 {
				font-size: 2rem;
				line-height: 2.2rem;
			}
		}
	}
}

.results_container {
	.results_epreuve {
		position: relative;
		display: flex;
		flex-direction: column;

		.filtres_results { order: 1; }
		.table_container { order: 2; }

		.loader {
			position: absolute;
			top: 300px;
			left: 50%;
			transform: translateX(-50%);
			height: 100px;
			width: 100px;

			// Contenu loader, voir bas fichier
		}

		.table_container.loading {
			opacity: 0.5;
			pointer-events: none;
		}
	}
	.results_epreuve.hidden { display: none; }
}
.results_container.details {
	display: flex;
	flex-direction: column;

	.entete_details {
		order: 1;
		margin-bottom: 20px;
		text-align: center;
	}

	.results_details {
		order: 2;

		.table-cell.icone {
			width: 40px;
			img {
				width: 36px;
				margin: 5px 0;
			}
		}

		.table-cell {
			p {
				margin: 0;
				padding: 12px 0;
				line-height: 1rem;
			}
		}
	}

	.point {
		text-align: left;
	}

	.transition {
		background-color: $color_vert;
		background-color: lighten($color_gris, 25%);
	}
}

@include media-breakpoint-down(sm) {
	.results_container {
		.results_epreuve {
			.table_container {
				overflow-x: scroll;
			}
		}
	}
}


.select_epreuve, .select_categorie {
	cursor: pointer;
	padding: 4px;
	min-width: 200px;
	border: none;
	border-radius: 10px;
	color: $color_white;
	background-color: $color_gris;
	font-size: 1.1rem;
	letter-spacing: 0.06rem;
	font-weight: 900;
	font-style: italic;
	text-align: center;
	background-image: url('/images/select_arrow.png');
    background-position : right center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    text-align-last: center;
    padding-right: 20px;
}
.select_categorie {
	font-size: 1.3rem;
	padding: 2px;
}

.filtres_results {
	display: flex;
	align-items: flex-end;
	margin-bottom: 20px;
	//justify-content: space-between;

	> * {
		cursor: pointer;
		padding: 0 10px;
	}

	> *:first-child {
		padding-left: 0;
	}

	.step {
		display: block;
		cursor: pointer;
		text-align: center;
		opacity: 0.8;
		transition: 0.3s;

		.picto {
			width: 36px;
			margin-bottom: 10px;
			opacity: 0.5;
			transition: 0.3s;
		}
	}
	.step.active {
		opacity: 1;
		.picto {
			opacity: 1;
		}
	}

	.categorie {
		text-align: center;

		label {
			display: block;
			width: 100%;
			margin-bottom: 6px;
			font-size: 1.3rem;
			font-style: italic;
		}
	}

	.clean_filters, .clean_search {
		text-align: center;
		padding: 0;
		pointer-events: none;
		opacity: 0;
		transition: 0.5s;
		width: 50px;

		label {
			display: block;
			width: 100%;
			margin: 0;
			font-size: 0.85rem;
			line-height: 0.85rem;
			font-style: italic;
			margin-bottom: 4px;
		}

		i {
			font-size: 2.3rem;
		}
	}
	.clean_search {
		margin-right: 30px;
	}
	.spacer { flex: 1; }
	.clean_filters {
		i {
			transform: scale(0.8);
		}
	}
	.clean_filters.active, .clean_search.active {
		pointer-events: all;
		opacity: 1;
	}

	.btn, select {
		min-width: auto;
		font-size: 1.25rem;
		padding-left: 12px;
		padding-right: 12px;
	}
	select {
		padding-left: 16px;
		padding-right: 22px;
	}
}

.bouton_filters, .entete_tableau_resp {
	display: none;
}


@include media-breakpoint-down(md) {

	.container.results {
		padding: 16px;
	}

	.select_epreuve, .select_categorie {
		-webkit-appearance: none;
	}

	.entete_results {
		flex-direction: column;
		margin-bottom: 10px;

		.titre {
			padding: 0;
		}

		.choix_epreuve {
			margin-top: 20px;
			padding: 0;
			text-align: center;

			select {
				min-width: 80%;
			}
		}
	}

	.results_container {
		.results_epreuve {

			.table-cell {
				font-size: 0.95rem;
				//line-height: 1.1rem;

			}

			.vmoyenne, .gain { display: none; }
		}
	}

	.results_container.details {
		.entete_details {
			text-align: left;
			margin-top: 20px;
			margin-bottom: 0 !important;
		}
		.head .table-cell {
			font-size: 0.85rem;
		}
	}

	.entete_tableau_resp {
		display: flex;
		justify-content: space-between;
		margin: 10px 0;

		h3 {
			font-size: 2.3rem;
			font-weight: 900;
			font-style: italic;
			margin: 0;
		}

		p {
			font-style: italic;
			margin: 0;
		}

		.bouton_filters {
			cursor: pointer;
			display: inline-block;
			padding: 6px 12px;

			img {
				width: 36px;
			}
		}

	}

	.filtres_results {
		display: block;
		position: absolute;
		background-color: lighten($color_gris, 35%);
		top: 100px;
		width: 100%;
		border-radius: 5px;
		padding: 0px;
		transition: 0.3s;
		max-height: 0px;
		overflow: hidden;

		.clean_filters { display: none; }

		.step {
			margin-bottom: 10px;

			.picto, br { display: none; }
		}

	}
	.filtres_results.active {
		max-height: 520px;
		padding: 20px;
	}

}

.no_results_found {
	margin-top: 10px;
	text-align: center;
	font-size: 1.2rem;
	font-weight: 700;
}

.no_results_found.squared {
	padding: 20px;
	color: $color_white;
	background-color: $color_gris;
}




// SPECIFIQUE
section.entete {
	.fond {
		object-fit: cover;
		width: 100%;
		height: 110vh;
	}
}

section.entete.accueil {
	.overlay {
		top: 45%;
	}
}

section.entete.generale {
	.fond {
		height: calc(40vh + 15vw);
	}

	.triangle.basdroite.gris {
	    border-top: 10vw solid transparent;
	    border-bottom: 10vw solid $color_gris;
	}
}

.page_classic {
	.col_gauche {
		padding-right: 30px;
	}
	.col_droite {
		position: relative;
		padding-left: 25px;

		.filet {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 1px;
		}
	}
}

section.explication_timeinlive {
	.illu_appli {
		width: 115%;
		margin-bottom: 100px;
	}

	.boutons_dl_appli_container {
		img {
			width: 250px;
		}
	}
}

section.contact {
	.adresse_container {
		p { 
			font-size: 1.4rem;
			line-height: 1.9rem;
		}

		.txt_marron {
			font-weight: 700;
		}

		.adresse {
			color: $color_white;
		}
	}

	.module_timeinlive {
		top: 200px;
	}

	textarea {
		height: 200px;
	}

	.submit {
		cursor: pointer;
		color: $color_black;
		border-radius: 0;
		background-color: transparent;
		border-bottom: 2px solid $color_black;
	}
	.submit.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	.form-group:last-child {
		text-align: center;
	}
}


@include media-breakpoint-down(md) {

	section.entete.accueil {
		.overlay {
			top: 45%;
			width: 80%;

			.compteur, .phrase_bottom {
				transform: none;
			}

			.compteur {
				font-size: 5rem;
				//text-align: center;
			}

			.phrase_bottom {
				font-size: 2rem;
			}
		}

		.fond {
			height: 98vh;
		}
	}

	section.corps {
		z-index: 100;
		.col_gauche {
			margin-bottom: 50px;
		}
	}

	#corps_accueil {
		padding: 60px 0;
		.container > .row {
			.col_droite { order: 1; }
			.col_gauche { 
				order: 2; 
				display: flex;
				flex-direction: column;

				.module_timeinlive_container { order: 1; }
				> * { order: 2; }
			}
			
		}

		.bouton_type_2 {
			width: 100%;
		}
	}

	section.explication_timeinlive {
		.illu_appli {
			width: 80%;
			margin-bottom: 40px;
		}

		.boutons_dl_appli_container {
			a {
				display: block;
			}
			img {
				width: 150px;
			}
		}
	}

	section.contact {
		.col_droite { order: 1; }
		.col_gauche { order: 2; }

		.module_timeinlive {
			position: relative;
			top: 0;
		}

		.adresse_container {
			margin-bottom: 50px;

			.txt_marron {
				color: $color_vert;
			}

			.adresse {
				color: $color_white;
			}
		}
	}
}

@include media-breakpoint-down(sm) {

	section.entete.accueil {
		.overlay {
			width: 80%;
		}
	}
}




// CSS LOADER
.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 6px;
  border: 6px solid $color_gris;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $color_gris transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


// BANDEAU BAS MOBILE
.bandeau_bas_mobile { display: none; }
@include media-breakpoint-down(sm) {

	.bandeau_bas_mobile {
		display: flex;
		position: fixed;
		z-index: 1000;
		bottom: 0;
		width: 100%;
		background-color: $color_gris;

		.btn {
			border-radius: 0;
			display: block;
			flex: 1;
			padding: 6px 20px;
		}

		.timeinlive {
			background-color: $color_black;
		}

		.resultats {
			background-color: $color_vert;
		}
	}
}
.full_site.resultats, .full_site.resultats_evenement, .full_site.resultats_participant {
	.bandeau_bas_mobile {
		display: none;
	}
}

// ----------------------------------------------------------





// TEST POUR PATRICK

.triangle.hautgauche.vert {
    border-left: 50vw solid $color_gris;
    border-top: 15vw solid $color_gris;
}

.corps.gradiant {
    background: none;
    background-color: $color_gris;
    min-height: auto;
}

.encart_basdroite .triangle_coin {
    border-bottom: 30vw solid rgba($color_vert, 0.8);
    border-right: 30vw solid rgba($color_vert, 0.8);
}

.year_container .htmltable {
	margin: 0;
}

footer .adresse .phone {
    color: $color_gris !important;
}

.module_timeinlive {
	background-color: $color_vert;

	.txt_vert {
		color: $color_gris;
	}

	.btn {
		background-color: $color_black;
	}
}
.cadre_timeinlive {
	.btn {
		background-color: $color_black;
	}
}


@include media-breakpoint-down(sm) {
	.encart_basdroite .triangle_coin {
	    border-bottom: 50vw solid rgba($color_vert, 0.8);
	    border-right: 50vw solid rgba($color_vert, 0.8);
	}
}