
// ----------------------------------
// ||            Footer            ||
// ----------------------------------


// ----------------- CSS SPECIFIQUE AU SITE -----------------


footer {
	.overlay {
		.trait_oblique {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.logos_container {
		position: absolute;
		z-index: 2;
		bottom: 40px;
		left: 40px;
		width: 500px;

		@include media-breakpoint-down($responsive_breakpoint) {
			width: 100%;
		}

		.logos_haut {
			display: flex;
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 2px solid $color_white;

			.spacer { width: 30px; }

			img {
				width: 100%;
			}

			.logo_njuko {
				//transform: translateX(20px);
			}

			.logo_cmcbs {
				min-width: 150px;
				@include media-breakpoint-down($responsive_breakpoint) {
					min-width: initial;
				}
			}
		}

		.logo_footer {
			width: 300px;
		}
	}

	.adresse {
		position: absolute;
		bottom: 40px;
		right: 40px;
		color: $color_white;

		> * {
			margin: 0;
			font-size: 1.4rem;
			letter-spacing: 0.05rem;
		}

		> a {
			display: block;
			color: $color_white;
			text-decoration: none;
		}

		.phone {
			font-size: 2.4rem;
			color: $color_vert;
		}

		.mail {
			font-size: 1.1rem;
		}
	}

	.triangle_container {
		position: absolute;
		top: 0;
	}

	.fond {
		z-index: 0;
		object-fit: cover;
		width: 100%;
		height: 110vh;
	}

	.baseline {
		margin: 0;
		margin-top: 10px;

		p, a, button {
			font-family: "interface", sans-serif;
			font-size: 0.8rem;
			letter-spacing: 0;
			font-weight: 300;
			font-style: normal;
			color: $color_white;
			margin: 0;
		}
		a:hover, button:hover {
			color: $color_gris;
		}

		button {
			cursor: pointer;
			border: none;
			background-color: transparent;
			padding: 0;
			text-decoration: underline;
		}
	}
}




// ----------------------------------------------------------


// FOOTER - GÉNÉRAL
footer {
	position: relative;
	z-index: 1;
	background-color: $footer_bgcolor;
	padding: $footer_padding;
	.separateur { width: $footer_separateur_horizontal; }
	color: $footer_txtcolor;
	a {
		color: $footer_link_color;
		@if $footer_link_underline == 1 { text-decoration: underline; }
	}
	a:hover {
		color: $footer_link_hover_color;
	}
}


@include media-breakpoint-down($responsive_breakpoint) {

	footer {
		.separateur { width: 100%; height: $footer_separateur_vertical; }

		.logos_container {
			left: 50%;
			bottom: 270px;
			transform: translateX(-50%);
		}

		.adresse {
			text-align: right;
			bottom: 55px;
			right: 20px;
		}

		.overlay {
			top: 30%;
		}

		.filler_bas {
			height: 30px;
			background-color: rgba($color_vert, 0.8);
		}

		.fond {
			height: 160vh;
		}
	}

	.full_site.resultats, .full_site.resultats_evenement, .full_site.resultats_participant {
		footer {
			.adresse {
				bottom: 20px;
			}
			.filler_bas {
				display: none;
			}
		}
	}

}